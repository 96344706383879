export const Features = [
  {
    image: 'https://www.mlytics.com/wp-content/uploads/2021/11/icon_Ultrafast.png',
    title: 'dns.section3.card1.title',
    content: 'dns.section3.card1.content'
  },
  {
    image: 'https://www.mlytics.com/wp-content/uploads/2021/08/icon_Time-saving.png',
    title: 'dns.section3.card2.title',
    content: 'dns.section3.card2.content'
  },
  {
    image: 'https://www.mlytics.com/wp-content/uploads/2021/11/icon_Crafted-for-any-CDN.png',
    title: 'dns.section3.card3.title',
    content: 'dns.section3.card3.content'
  }
]
